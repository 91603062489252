import React, { FunctionComponent } from 'react'
import Img from 'gatsby-image'
import ScrollIndicator from '../../atoms/scroll-indicator/ScrollIndicator'
import { spacing } from '../../../utils/spacing'
import styled from 'styled-components'
import useDictionary from '../../../hooks/use-dictionary/useDictionary'
import useImageData from '../../../hooks/use-image-data/useImageData'
import { ImageNodeData } from '../../../contexts/images/ImagesContext'
import useWindowSize from '../../../hooks/use-window-size/useWindowSize'

interface StaticHero {
  image?: string
  scrollIndicatorText?: string
}

const StaticHero: FunctionComponent<StaticHero> = ({
  image,
  scrollIndicatorText = 'scrollToExplore',
}) => {
  const dictionary = useDictionary()
  const imageData = useImageData(`hero/${image || ''}`) as ImageNodeData
  const [, windowHeight] = useWindowSize()

  return (
    <StyledStaticHero height={`${windowHeight}px`}>
      {imageData && (
        <Img
          fluid={imageData.fluid}
          loading="eager"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <ScrollIndicatorWrapper>
        <ScrollIndicator text={dictionary.get(scrollIndicatorText)} />
      </ScrollIndicatorWrapper>
    </StyledStaticHero>
  )
}

interface StyledStaticHeroProps {
  height: string
}

const StyledStaticHero = styled.div<StyledStaticHeroProps>`
  ${spacing()};
  position: relative;
  overflow: hidden;
  height: ${({ height }): string => height};
`

const ScrollIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
`

export default StaticHero
