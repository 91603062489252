import React, { Fragment, FunctionComponent } from 'react'
import Sections, { SectionProps } from '../organisms/sections/Sections'
import Colors from '../../tokens/Colors'
import Footer from '../organisms/footer/Footer'
import { Helmet } from 'react-helmet'
import MetaData from '../../types/MetaData'
import StaticHero from '../organisms/static-hero/StaticHero'
import styled from 'styled-components'
import useImageData from '../../hooks/use-image-data/useImageData'

interface ContentPageProps {
  pageContext: {
    hero: {
      image: string | null
      scrollIndicatorText: string | null
    } | null
    meta: MetaData
    route: string
    sections: SectionProps[]
    slug: string
    title: string
  }
}

const ContentPage: FunctionComponent<ContentPageProps> = ({
  pageContext: { hero, meta, route, sections },
}) => {
  const ogImage = useImageData(meta.og?.image)

  return (
    <Fragment>
      <Helmet>
        <title>{meta.title}</title>
        <meta content={meta.description} name="description" />
        <meta content={meta.og?.title || meta.title} name="og:title" />
        <meta
          content={meta.og?.description || meta.description}
          name="og:description"
        />
        {ogImage?.fixed?.src && (
          <meta content={meta.siteUrl + ogImage.fixed.src} name="og:image" />
        )}
      </Helmet>
      <StyledContentPage>
        {hero && (
          <StaticHero
            image={hero.image || undefined}
            scrollIndicatorText={hero.scrollIndicatorText || undefined}
          />
        )}
        <Sections sections={sections} />
        <Footer />
      </StyledContentPage>
    </Fragment>
  )
}

const StyledContentPage = styled.div`
  background: ${Colors.WHITE};
`

export default ContentPage
